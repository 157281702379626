@font-face {
    font-family:"Nunito-light";
    src: url("../fonts/Poppins-Light.ttf");
}
@font-face {
    font-family:"Nunito-bold";
    src: url("../fonts/Poppins-ExtraBold.ttf");
}
@font-face {
    font-family:"Nunito-bold-italic";
    src: url("../fonts/Poppins-BoldItalic.ttf");
}
@font-face {
    font-family:"Nunito-light-italic";
    src: url("../fonts/Poppins-LightItalic.ttf");
}
@font-face {
    font-family: "Nunito-regular";
    src: url("../fonts/Poppins-Regular.ttf");
}
@font-face {
    font-family: "Nunito-medium";
    src: url("../fonts/Poppins-Medium.ttf");
}