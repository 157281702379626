.flex-center-row {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.modal-backdrop {
  position: "fixed";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(196, 196, 196, 0.95);
  /* background-color:rgba(0,0,0,0.1); */
  padding: 50;
  display: flex;
  align-items: center;
  justify-content: center;
}
.progress-bar {
  background-color: #00ca81;
}
.modalStyle {
  background-color: #fff;
  max-height: 320px;
  max-width: 750px;
  border-radius: 10px;
  margin: 0 auto;
  padding: 30px;
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.modal-input {
  border-radius: 10px;
  width: 60%;
  text-align: center;
  height: 45px;
  font-family: "Nunito-regular";
  font-weight: 400;
}
.button-container {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}
.modal-confirm-button {
  background: #00ca81;
  font-size: 24px;
  border: none;
  border-radius: 10px;
  color: #fff;
  font-size: 24px;
  font-family: "Nunito-regular";
  font-weight: 700;
  padding: 0 30px;
}
.modal-cancel-button {
  background: #f35858;
  font-size: 24px;
  border: none;
  border-radius: 10px;
  color: #fff;
  font-size: 24px;
  font-family: "Nunito-regular";
  font-weight: 700;
  padding: 0 30px;
}
@media only screen and (max-width: 1200px) {
  .heading {
    font-size: 2.5em;
    text-align: center !important;
  }
  .home-sub {
    text-align: center;
    font-size: 24px;
  }
  .vert-center {
    justify-content: space-around;
  }
}
@media only screen and (max-width: 512px) {
  .heading {
    font-size: 2em;
    text-align: center !important;
  }
  .srtMain {
    height: 100vh !important;
  }
  .sub-heading {
    font-size: 36px;
    text-align: center !important;
  }
  .username {
    font-size: 24px;
  }
  .project-item {
    font-size: 20px;
  }
  .try_now {
    width: 100%;
    font-size: 18px;
  }
}
@media screen and (max-width: 450px) {
  .srtEditButton {
    font-size: 8px;
  }
  .srtDelButton {
    font-size: 8px;
  }
  .srtbutton {
    font-size: 8px;
  }
  .font-sizer {
    font-size: 10px;
  }
  .heading {
    text-align: center !important;
  }
}

@media screen and (max-width: 992px) {
  .hide-below {
    width: 100%;
    margin: 10px 0 20px 0;
  }
  .hide-below img {
    width: 15%;
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
  }
  .model-image-size {
    width: 60%;
    margin: auto;
  }
}

@media (max-width: 540px) {
  .model-image-size {
    width: 100%;
    margin: auto;
  }
  .mins-less {
    font-size: 18px;
  }
  .home-sub {
    font-size: 16px;
    margin-bottom: 8px;
  }
}

/* home page new responsive  */
@media (max-width: 1199px) {
  .shareable-podcast .heading {
    text-align: left !important;
  }
  .not-yourself h3 {
    font-size: 60px;
  }
  .not-yourself p {
    font-size: 22px;
  }
}
@media (max-width: 991px) {
  .service {
    margin-top: 0;
  }
  .not-yourself h3 {
    font-size: 45px;
  }
  .not-yourself p {
    font-size: 20px;
  }
  .navbar-menu {
    text-align: center;
    justify-content: center;
  }
  .testimony-main {
    margin: auto;
  }
  .testimony-box div {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .Start-your {
    margin-top: 30px !important;
  }
  .shareable-podcast p {
    text-align: center;
  }
  .video-gif {
    margin-top: 50px;
  }
  .not-yourself {
    margin-top: 80px;
    margin-bottom: 60px;
  }
}
@media (max-width: 767px) {
  .features {
    margin-top: 60px;
  }
  .service h2 {
    margin-top: 20px;
  }
  .service img {
    width: 60px;
  }
  .not-yourself {
    margin-top: 80px;
    margin-bottom: 60px;
  }
  .not-yourself h3 {
    font-size: 36px;
    margin-bottom: 20px;
  }
  .not-yourself {
    padding: 40px 15px;
  }
  .emailsubcribe {
    width: auto;
  }
  .shareable-podcast .heading {
    text-align: center !important;
  }
  .talk-us-now h2 {
    font-size: 30px;
    color: #fff;
    line-height: 45px;
  }
  .talk-us-now p {
    margin-bottom: 40px;
  }
  .how-does-work {
    width: 100%;
    margin: auto;
    padding-bottom: 60px;
    margin-top: 80px;
  }
  .talk-us-now h2 {
    margin-top: 80px;
  }
  /* audio-video-section */
  .audio-video-main {
    margin-top: 30px;
    padding: 15px;
  }
  .audio-video-tabs {
    width: auto;
  }
  .rc-tabs-tab-btn {
    text-align: center;
  }
}
@media (max-width: 540px) {
  .next-btn {
    margin-top: 60px;
  }
  .images-dscription {
    margin-top: 40px;
  }
  .videoupload-section,
  .imagesupload-section {
    width: auto;
  }
  .video-btn,
  .audio-btn {
    width: 150px;
    height: 42px;
  }
  .video-btn svg,
  .audio-btn svg {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }
  .video-btn p,
  .audio-btn p {
    font-size: 16px;
  }
  .rc-tabs-tab:nth-child(2)::before {
    width: 180%;
  }
  .video-audio-btn {
    margin-top: 30px;
    width: 320px;
  }
  .navbar-menu .home {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .rc-tabs-tab-btn {
    font-size: 14px;
    font-weight: bold;
  }
  .navbar-menu a {
    margin-right: 0px !important;
    font-size: 16px !important;
  }
  .grad-custom {
    height: 100px !important;
  }
  .pricing-per-month {
    font-size: 38px !important;
  }
  .pricing-table {
    width: 100% !important;
  }
  .footer {
    padding: 40px 0;
  }
}
@media (max-width: 350px) {
  .video-audio-btn {
    margin-top: 30px;
    width: auto;
    display: flex;
    justify-content: center;
  }
}

/* signup-design */
@media (min-width: 992px) {
  .signup-trial.modalStyle {
    width: 992px;
    max-height: 618px;
    border-radius: 33px;
    border: none;
  }
  .signup-trial.modalStyle .login-box {
    width: auto;
    background: transparent;
  }
  .signup-trial.modalStyle .login-box h3 {
    color: #222;
    font-size: 48px;
    font-weight: 600;
  }
  .signup-trial.modalStyle .login-box p {
    color: #222;
    font-size: 18px;
    font-weight: 600;
  }
  .signup-trial.modalStyle .login-box .custom-input {
    width: 60% !important;
  }
  .signup-trial.modalStyle .login-box .signup-submit-button {
    width: 60% !important;
    background: #a158f7 !important;
  }
  .signup-trial.modalStyle .open-ids {
    width: 60%;
  }
}
@media (max-width: 992px) {
  .signup-trial.modalStyle {
    width: fit-content;
    margin: 0 15px;
    max-height: 618px;
    border-radius: 33px;
    border: none;
    padding: 0px;
  }
  .signup-trial.modalStyle .login-box {
    width: auto;
    background: transparent;
  }
  .signup-trial.modalStyle .login-box h3 {
    color: #222;
    font-size: 34px;
    margin-top: 20px;
  }
  .signup-trial.modalStyle .login-box p {
    color: #222;
    font-size: 16px;
    font-weight: 600;
  }
  .signup-trial.modalStyle .login-box .custom-input {
    width: 90% !important;
  }
  .signup-trial.modalStyle .login-box .signup-submit-button {
    width: 90% !important;
    background: #a158f7 !important;
  }
  .signup-trial.modalStyle .open-ids {
    width: 90%;
  }
}

/* full-episode-responsive */
@media (min-width: 768px) {
  .mobile-clipsection span {
    display: none;
  }
  .item-list-height {
    height: 40vh;
  }
  .mobile-clipsection {
    margin-bottom: 12px;
  }
}
@media (max-width: 767px) {
  .clip-item-inner .clip-item {
    border-radius: 50px !important;
    margin-right: 0;
  }
  .transcript-main .font-sizer div {
    display: none;
  }
  .mobile-clipsection {
    padding-left: 15px;
    margin-top: 15px;
  }
  .mobile-clipsection .col-1.font-sizer,
  .mobile-clipsection span {
    width: auto;
    padding: 0;
    font-size: 14px;
  }
  .mobile-clipsection span {
    padding: 0 6px;
  }
  .mobile-clipsection .col-4.font-sizer {
    width: 100%;
    padding-left: 0;
    font-size: 14px;
  }
  .item-list-height {
    height: auto;
  }
}
