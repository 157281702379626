.logout {
  background-color: rgba(255, 55, 55, 0.6);
  width: 125px;
  border-radius: 10px;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
* {
  scroll-behavior: smooth;
}
.logout:link {
  text-decoration: none;
  font-family: "Nunito-Bold";
  font-size: 20px;
}
.navbar-toggler {
  border: none;
}
.custom-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(126, 31, 219, 1)' stroke-width='3' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}
.navbar-toggler:focus {
  box-shadow: none;
}
.logout:hover {
  color: white;
}
.home {
  font-family: "Nunito-Bold";
  text-decoration: none;
  font-size: 20px;
  line-height: 27px;
  color: #7e1fdb;
  display: flex;
  align-items: center;
}
.home:hover {
  color: #7e1fdb;
}
.link {
  font-family: "Nunito-regular";
  text-decoration: none;
  font-size: 20px;
  line-height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #7e1fdb;
}
.link:hover {
  color: #7e1fdb;
}
.new_link {
  font-family: "Nunito-regular";
  text-decoration: none;
  font-size: 20px;
  line-height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 700;
  cursor: pointer;
}
.new_link:hover {
  color: #fff;
  text-decoration: none;
}
.vert-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.sub-heading {
  font-family: "Nunito-bold-italic";
  margin: auto;
  color: rgba(0, 0, 0, 1);
  font-size: 48px;
}
.selection-heading {
  text-align: center;
  font-family: "Nunito-bold";
}
.login-box {
  background: linear-gradient(0deg, #7117ea, #7117ea);
  width: 325px;
  height: 100%;
  border-radius: 20px;
}
.or {
  color: #fff;
  margin: auto;
  padding: 12px;
}
.custom-input {
  width: 80%;
  margin: auto;
  border-radius: 10px;
  height: 45px;
}
.signup-submit-button {
  display: flex;
  border: none;
  width: 80%;
  margin: auto;
  border-radius: 10px;
  height: 40px;
  background: #c283ff99;
  color: #fff;
  font-family: "Nunito-regular";
  font-family: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-button {
  color: #fff;
  border: 2px white solid;
  background: none;
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 40px;
  margin: auto;
  font-family: "Nunito-bold";
}
.create-account-button {
  color: #fff;
  border-radius: 10px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin: auto;
  font-family: "Nunito-bold";
  background: rgba(194, 131, 255, 0.6);
  text-decoration: none;
}
.create-account-button:hover {
  color: #fff;
}

.username {
  font-family: "Nunito-bold";
  font-size: 36px;
}

.project-item {
  background: #e1e1e1;
  color: #000;
  font-family: "Nunito-bold";
  font-size: 30px;
  height: 175px;
  margin: 15px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  word-wrap: break-word !important;
  word-break: break-word !important;
}
.project-item .projstringname {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding: 0 10px;
}
.clip-item {
  background: #e1e1e1;
  color: #000;
  font-family: "Nunito-bold";
  font-size: 30px;
  height: 175px;
  margin: 15px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  word-wrap: break-word !important;
  word-break: break-word !important;
}
.clip-item:hover {
  background: #7117ea;
  color: #fff;
  transition: ease 0.3s;
}
.upload-file-button {
  border: none;
  width: 200px;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  margin: auto;
  text-align: center;
  padding: 10px;
  background: #00ca81;
}
.selection-box {
  height: 260px;
  width: 300px;
  border-radius: 10px;
  margin: auto;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  text-decoration: none;
  color: #fff;
}
.selection-box:hover {
  color: #fff;
  cursor: pointer;
}
.selection-item {
  font-family: "Nunito-bold-italic";
  font-size: 24px;
  /* margin: 20px; */
  text-align: center;
  font-weight: 400;
}
.lower-text {
  font-family: "Nunito-light";
  text-align: center;
  font-weight: 400;
}

.upload {
  background: #7117ea;
  border: none;
  width: 200px;
  border-radius: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: "Nunito-bold";
}
/* .container{
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
} */

.afterUpload-Heading {
  color: #7e1fdb;
  margin: auto;
  text-align: center;
  font-family: "Nunito-Bold";
  font-size: 36px;
  padding: 20px;
  display: flex;
  align-items: center;
  margin-top: 100px;
}
.afterUpload-Sub-heading {
  margin: auto;
  text-align: center;
  font-family: "Nunito-Bold";
  color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  font-size: 24px;
}

.videoPlayer {
  display: flex;
  text-align: center;
  justify-content: center;
  align-content: center;
}

.srtinput {
  border: none;
  background: #eeeeee;
  border-radius: 10px;
  padding: 5px;
}
.srtinput:focus {
  outline: none;
}
.label-text {
  font-family: "Nunito-Bold";
}
.srtbutton {
  background: #7117ea;
  width: 90px;
  margin: 15px;
  border-radius: 10px;
  height: 30px;
  border: none;
  color: white;
  font-family: "Nunito-Bold";
  font-size: 14px;
  font-weight: 700;
}
.explainer {
  color: #ff3737;
  text-align: center;
  font-size: 36px;
  font-family: "Nunito-Bold";
}
.sous-explainer {
  color: rgba(0, 0, 0, 0.6);
  text-align: center;
  font-size: 24px;
  font-family: "Nunito-Bold";
}
.srtEditButton {
  background: none;
  border: 1px solid #7117ea;
  width: 80%;
  margin: auto;
  border-radius: 4px;
  color: #7117ea;
}
.srtDelButton {
  background: red;
  border: none;
  width: 80%;
  margin: auto;
  border-radius: 4px;
  color: white;
}
.instruction-text {
  font-family: "Nunito-Light";
  color: #858585;
  font-weight: 600;
}
.head-container {
  padding: 0;
  width: 60%;
  margin: auto;
}
.heading {
  font-family: "Nunito-bold";
  font-size: 3em;
  font-weight: 700;
  line-height: 1.6;
}
.home-sub {
  font-family: "Nunito-medium";
  text-align: left;
  font-size: 24px;
  color: #7b7b7b;
}
.tassle_action {
  width: 50%;
  /* margin:auto; */
}
.testimony-box {
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.image {
  border-radius: 10px;
  width: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  height: 300px;
}
.information-left {
  background: #f1f1f1;
  border-radius: 10px;
  width: 100%;
  min-height: 250px;
  display: flex;
  font-size: 24px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 50px;
  font-family: "Nunito-bold";
  margin-top: 20px;
}
.information-right {
  background: #7117ea;
  color: white;
  font-weight: 600;
  font-size: 20px;
  padding: 20px;
}
.try_now {
  width: 327px;
  height: 57px;
  background: #7117ea;
  color: #fff;
  font-weight: 400;
  font-size: 20px;
  padding: 0px !important;
  background: linear-gradient(92.13deg, #7117ea -26.66%, #e85f64 209.91%);
  border-radius: 5px;
  text-align: center;
}
.try_now img {
  margin-left: 10px;
}
.gray-color {
  color: rgba(0, 0, 0, 0.5);
  margin: auto;
  text-align: center;
}
.gray-color:hover {
  color: rgba(0, 0, 0, 0.5);
}
.pricing-button {
  padding: 40px;
  background: #00ca81;
  border-radius: 50px;
}
.pricing-info {
  width: 60%;
  font-size: 24px;
  text-align: center;
  font-family: "Nunito-regular";
  font-weight: 700;
  line-height: 1.6;
}
.action_sub {
  color: rgba(0, 0, 0, 0.5);
  font-family: "Nunito-regular";
  font-weight: 400;
}

.nav-custom {
  box-shadow: 0px 0px 21px -5px;
}

.grad-custom {
  background-image: linear-gradient(
    rgba(194, 131, 255, 0.34),
    rgba(194, 131, 255, 0)
  );
}

/*  home-page-customize */
.how-does-work {
  width: 100%;
  margin: auto;
  padding-bottom: 105px;
  margin-top: 150px;
}
.pricing-information {
  margin-top: 20px;
}
.pricing-information ul {
  border: 5px solid #debdff;
  padding: 10px;
  border-radius: 20px;
  display: inline-block;
  font-weight: 500;
  max-width: 615px;
}
.pricing-information ul li {
  line-height: 1.5;
  font-size: 20px;
  margin-bottom: 15px;
  list-style-type: none;
  position: relative;
  padding-left: 15px;
}
.pricing-information ul li:after {
  position: absolute;
  content: "";
  height: 5px;
  width: 5px;
  background-color: #000;
  top: 50%;
  border-radius: 50px;
  left: 0;
  transform: translateY(-50%);
}
.talk-us-now {
  background: linear-gradient(266.99deg, #7117ea -5.4%, #da5776 162.56%);
  margin-top: 60px;
}
.talk-us-now h2 {
  font-size: 36px;
  color: #fff;
  line-height: 54px;
  text-align: center;
  margin-top: 100px;
  margin-bottom: 60px;
  margin-bottom: 40px;
}
.talk-us-now h2 span {
  display: inline-block;
  position: relative;
}
.talk-us-now h2 span:after {
  content: "";
  position: absolute;
  height: 5px;
  width: 100%;
  background-color: #000;
  bottom: 0;
  left: 0;
}
.talk-us-now p {
  text-align: center;
  color: #fff;
  opacity: 0.8;
  margin-bottom: 50px;
}
.talk-us-now a {
  text-align: center;
  margin: auto;
  background: transparent;
  border: 1px solid;
  width: auto;
  padding: 0 35px !important;
  margin-bottom: 50px;
}
.footer {
  background-color: #191919;
  padding: 70px 0;
  margin-left: -15px;
  margin-right: -15px;
}

/* video audio re-design */
.audio-video-main {
  padding: 50px;
  margin-top: 50px !important;
}
.rc-tabs-ink-bar.rc-tabs-ink-bar-animated {
  display: none;
}
.rc-tabs-nav-list {
  display: flex;
  justify-content: center;
}
.audio-video-tabs {
  width: 600px;
  margin: auto;
}
.rc-tabs-content-holder {
  text-align: center;
}
.rc-tabs-tab {
  color: #b0b0b0;
  font-weight: 500;
  position: relative;
}
.rc-tabs-tab.rc-tabs-tab-active {
  color: #7e1fdb;
  font-weight: 500;
}
.rc-tabs-tab.rc-tabs-tab-active::after {
  background-color: #7e1fdb;
}
.rc-tabs-tab::after {
  content: "";
  position: absolute;
  height: 11px;
  width: 11px;
  background-color: #b0b0b0;
  border-radius: 50px;
  left: 50%;
  top: -22px;
  cursor: pointer;
  transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
}
.rc-tabs-tab::before {
  content: "";
  position: absolute;
  height: 1px;
  width: 100%;
  background-color: #b0b0b0;
  border-radius: 50px;
  top: -17px;
}
.rc-tabs-tab:nth-child(1)::before {
  left: 50%;
}
.rc-tabs-tab:nth-child(2)::before {
  left: 50%;
  transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
}
.rc-tabs-tab:nth-child(3)::before {
  right: 50%;
}
.rc-tabs-nav-list .rc-tabs-tab:nth-child(2) {
  margin: 0 50px;
}
.rc-tabs-tab-btn {
  cursor: pointer;
}
.video-audio-btn {
  margin-top: 60px;
  margin-left: auto;
  margin-right: auto;
  width: 487px;
  display: flex;
  align-items: center;
  border-radius: 50px;
  background-color: #f3f3f3;
  padding: 6px;
}
.video-btn,
.audio-btn {
  width: 233px;
  height: 58px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  cursor: pointer;
}
.audio-btn {
  margin-left: 6px;
}
.video-btn.active,
.audio-btn.active {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 15%);
  border-radius: 50px;
}
.video-btn.active p,
.audio-btn.active p {
  color: #7e1fdb;
}
.video-btn svg,
.audio-btn svg {
  margin-right: 13px;
  fill: #808080;
}
.video-btn.active svg,
.audio-btn.active svg {
  fill: #7e1fdb;
}
.video-btn p,
.audio-btn p {
  font-size: 18px;
  font-weight: bold;
  color: #808080;
  margin-bottom: 0;
}
.videoupload-section {
  cursor: pointer;
  margin: auto;
  margin-top: 50px;
  width: 544px;
  color: #fff;
  background: #7117ea;
  padding: 30px 0 20px 0;
  border-radius: 10px;
  -o-border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  -ms-border-radius: 10px;
}
.imagesupload-section {
  cursor: pointer;
  margin: auto;
  margin-top: 40px;
  width: 544px;
  color: #fff;
  background: #e85f64;
  padding: 35px 0 40px 0;
  border-radius: 10px;
  -o-border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  -ms-border-radius: 10px;
}

.upload-btn-images {
  background: #ae3854;
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
  padding: 12px 50px;
}
.upload-btn {
  background: #5200a2;
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
  padding: 12px 50px;
}
.upload-btn p,
.upload-btn-images p {
  margin-bottom: 0px;
  margin-left: 10px;
  font-weight: 500;
}
.images-dscription {
  margin-top: 60px;
  font-size: 18px;
  font-weight: 500;
}
.images-dscription span {
  color: #e85f64;
}
.next-btn {
  margin-top: 140px;
  background: #7117ea;
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
  padding: 12px 90px;
  cursor: pointer;
  color: #fff;
}
.project-name {
  margin-top: 100px;
  border: 1px solid #000000;
  border-radius: 10px;
  -o-border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  -ms-border-radius: 10px;
}
.project-name h1 {
  margin-top: 45px;
  font-size: 24px;
  font-weight: bold;
}
.project-name p {
  font-size: 18px;
  margin-top: 20px;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
}
.project-name input {
  background: #ffffff;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 100px;
}
.next-btn.project-name-done {
  margin-top: 90px;
}
.audio-video-tabs .spinner {
  display: none;
}
.talk-us-now p a {
  text-decoration: none;
  border: none;
  padding: 0 !important;
  color: #fff;
}
.podcasting-done-today a {
  font-size: 17px;
}
.how-it-works-section {
  margin-top: 20px;
}
.how-it-works-section h3 {
  font-weight: bold;
  font-size: 22px;
}
.how-it-works-section p {
  font-weight: 500;
}
.features-include {
  font-weight: 500;
}
.features-include {
  font-weight: 500;
}
.shareable-podcast {
  margin-left: auto;
}
.emailsubcribe {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
}
.emailsubcribe input,
.emailsubcribe input:focus-visible,
.emailsubcribe input:focus {
  background: #ffffff;
  border: 1px solid #e8eaed;
  box-sizing: border-box;
  outline: none;
  padding: 0 40px;
  height: 45px;
  padding-right: 10px;
  border-radius: 5px;
  -o-border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -ms-border-radius: 5px;
}
.emailsubcribe img {
  position: absolute;
  margin-left: 9px;
  height: 22px;
}
.emailsubcribe .try_now {
  width: 100%;
  padding: 15px 10px !important;
  background: linear-gradient(92.13deg, #7117ea -26.66%, #e85f64 209.91%);
  border-radius: 5px;
  font-size: 20px;
  /* margin-left: 10px; */
  height: 45px;
}
.not-yourself {
  background: linear-gradient(92.13deg, #7117ea -26.66%, #e85f64 209.91%);
  color: #fff;
  padding: 75px;
  margin-top: 100px;
  margin-bottom: 75px;
  align-items: center;
}
.not-yourself h3 {
  font-size: 72px;
}
.not-yourself p {
  font-weight: 600;
  font-size: 24px;
}
.features {
  margin-top: 100px;
}
.service {
  text-align: center;
  padding: 25px 10px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  background: transparent;
  margin-top: 30px;
  transition: transform 0.5s, background 0.5s;
}

.service i {
  font-size: 40px;
  margin-bottom: 10px;
}

.service h2 {
  font-weight: 800;
  margin: auto;
  margin-bottom: 15px;
  font-size: 20px;
  margin-top: 30px;
  width: 240px;
}

.service:hover i {
  color: #fff;
}
.service p {
  max-width: 200px;
  margin: auto;
}

/* login - account facebook/google */
.open-ids {
  width: 255px;
  margin: 0 auto;
}

.auth-provider {
  font-weight: 500;
  font-size: 16px;
  line-height: 37px;
  padding: 0 21px;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 11px;
  box-sizing: border-box;
  border: 1px solid #d6d9dc;
  text-align: center;
  background: #fff;
}

.google-login {
  color: #535a60;
  border-color: #d6d9dc;
}

.facebook-login {
  color: #fff;
  background-color: #395697;
  border-color: transparent;
}

.telegram-login {
  margin-bottom: 12px;
}

.svg-icon {
  vertical-align: middle;
  padding-bottom: 4px;
}

/* full-episode */
.transcript-main {
  margin-left: 15px;
  margin-top: 20px;
}
.transcript-section h3 {
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 0px;
  font-size: 24px;
}
.transcript-section p {
  font-weight: 500;
  color: #878787;
  font-size: 14px;
  margin-bottom: 0;
}
.transcript-section p a {
  color: #7117ea;
  text-decoration: none;
}
.clip-item-main {
  display: flex;
  overflow-x: auto;
}
.clip-item-inner .clip-item {
  height: 45px;
  width: 100px;
  font-size: 20px;
  font-weight: 400;
  margin-right: 3px;
  cursor: pointer;
}
.clip-item-inner .clip-item.active {
  background: #7117ea;
  color: #fff;
}
